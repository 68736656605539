import Box from "common/components/Box";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Container from "common/components/UI/Container";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import FooterArea, {
  CopyrightText,
  LinksContainer,
  MenuArea,
  WidgetArea,
} from "./footer.style";

const links = [
  { id: 1, name: "Privacy Policy", to: "/privacy-statement" },
  { id: 2, name: "Copyright", to: "/copyright" },
  { id: 3, name: "Contact Us", to: "/contact" },
];

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        footer {
          logo {
            publicURL
          }
          menu {
            id
            link
            text
          }
          widgets {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);
  const { widgets } = data.appModernJson.footer;

  return (
    <FooterArea>
      <Container>
        <WidgetArea>
          {widgets.map((item) => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
          <LinksContainer>
            {links.map((link) => (
              <span className="link-container" key={link.id}>
                <Link className="footer-link" to={link.to}>
                  {link.name}
                </Link>
              </span>
            ))}
          </LinksContainer>
        </WidgetArea>
        {/* End of footer widgets area */}
        <MenuArea>
          {/* <Logo
            className="logo"
            href="/appclassic"
            logoSrc={logo.publicURL}
            title="App Classic"
          /> */}
          {/* <Menu>
            {menu.map((item) => (
              <MenuItem key={`footer-link${item.id}`}>
                <a href={item.link}>{item.text}</a>
              </MenuItem>
            ))}
          </Menu> */}
          <CopyrightText>Copyright © 2024 Comrex Pvt Ltd</CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  );
};

export default Footer;
